@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Inter-Light";
  src: url("../public/Fonts/Inter-Light.ttf");
}

@font-face {
  font-family: "Inter-Regular";
  src: url("../public/Fonts/Inter-Regular.ttf");
}

@font-face {
  font-family: "Inter-Medium";
  src: url("../public/Fonts/Inter-Medium.ttf");
}

@font-face {
  font-family: "Inter-SemiBold";
  src: url("../public/Fonts/Inter-SemiBold.ttf");
}

@font-face {
  font-family: "Inter-Bold";
  src: url("../public/Fonts/Inter-Bold.ttf");
}
