.loading-spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  background-color: black;
}

.spinner-logo {
  animation: flicker 0.5s infinite alternate;
}

@keyframes flicker {
  0% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
